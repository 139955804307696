
import React from 'react';
import ReactDOM from 'react-dom';
import FaucetHubHeader, { IFaucetHubHeaderProps } from './components/header/FaucetHubHeader';

(() => {
    let faucetWrappers = document.getElementsByClassName("faucet-wrapper");
    if(faucetWrappers.length == 0) return;
    let faucetWrapper: HTMLDivElement = faucetWrappers[0] as any;

    let headerProps: IFaucetHubHeaderProps = {
    };
    let headerComp = React.createElement<IFaucetHubHeaderProps>(FaucetHubHeader, headerProps, []);
    let headerWrapper = document.createElement("DIV");
    headerWrapper.className = "faucethub-header-container";
    ReactDOM.render(headerComp, headerWrapper);
    faucetWrapper.parentElement.insertBefore(headerWrapper, faucetWrapper);
})()
